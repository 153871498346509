import React, { useState } from "react"

import "./kontakt.styl"

const formLink = "https://form.lebenskreis-apotheke.at/process.php"

const validateForm = form => {
  // validate
  const result = { success: [], error: [] }
  const fd = new FormData(form)

  const missingValue = {
    name: "Dein Name fehlt.",
    text: "Dein Anliegen wurde nicht angegeben.",
    email: "Es wurde keine E-Mail Adresse angegeben.",
  }

  for (var data of fd.entries()) {
    const [key, value] = data

    if (!value) {
      result.error.push(missingValue[key])
    }

    // validate email
    if (
      value &&
      key === "email" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value)
    ) {
      result.error.push("Es wurde keine gültige E-Mail Adresse angegeben.")
    }
  }

  // if (result.error.length === 0) {
  //   result.success.push(
  //     "Die Anfrage wurde erfolgreich versendet. Wir werden Sie demnächst Kontaktieren."
  //   )
  // }

  return result
}

const Formular = () => {
  const [result, setResult] = useState({ success: [], error: [] })
  const [isRequestSended, setIsRequestSended] = useState(false)

  const onSubmit = e => {
    e.preventDefault()

    const validate = validateForm(e.target)
    if (validate.error.length === 0) {
      e.target.action = formLink
      const fd = new FormData(e.target)
      fd.append("form_tools_form_id", "7")

      fetch(formLink, {
        method: "POST",
        redirect: "manual",
        body: fd,
      }).then(response => {
        if (response.type === "opaqueredirect") {
          // data successfull
          // console.log("succesful")
          setResult({
            error: [],
            success: [
              "Die Anfrage wurde erfolgreich versendet. Wir werden Sie demnächst kontaktieren.",
            ],
          })
          setIsRequestSended(true)
        } else {
          // data failed
          // console.log("failed")
          setResult({
            error: [
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            ],
            success: [],
          })
        }
      })
    }
    setResult(validate)
  }

  return (
    <form
      id="contact-form"
      className="uk-width-1-2 uk-padding"
      method="post"
      onSubmit={onSubmit}
    >
      <h3>Ich habe folgendes Anliegen:</h3>
      <textarea
        className="uk-textarea"
        placeholder="Anliegen..."
        rows="12"
        name="text"
      />
      <p>
        <b>Mein Name:</b>
      </p>
      <input type="text" className="uk-input" placeholder="Name" name="name" />
      <p>
        <b>Meine E-Mail Adresse:</b>
      </p>
      <input
        type="email"
        className="uk-input"
        placeholder="Email"
        name="email"
      />
      <button
        id="anfrage-button"
        className="uk-margin"
        disabled={isRequestSended}
      >
        ANFRAGEN*
      </button>
      {result.error.map(err => (
        <p key={err} style={{ marginTop: "12px" }}>
          {err}
        </p>
      ))}
      {result.success.map(suc => (
        <p key={suc} style={{ marginTop: "12px" }}>
          {suc}
        </p>
      ))}
    </form>
  )
}

export default () => (
  <div id="contact" className="main-page">
    <h1 className="page-title">Kontakt</h1>
    <div className="uk-grid">
      <div className="uk-width-1-2">
        <h3>Lebenskreis Apotheke KG</h3>
        Tischlerstraße 15 <br />
        8740 Zeltweg <br />
        Österreich <br />
        <br />
        Tel.: <a href="tel:+43357724255">+43 35 77/242 55</a> <br />
        Fax: +43 35 77/242 55-4 <br />
        <a href="mailto:info@lebenskreis-apotheke.at">
          info@lebenskreis-apotheke.at
        </a>
        <br />
        <h3>Öffnungszeiten</h3>
        Mo bis Fr: 8.00 bis 18.00 Uhr <br />
        Sa: 8.00 bis 12.00
        <div className="small">
          * Wenn Sie die im Kontaktformular eingegebenen Daten durch Klick auf
          den nachfolgenden "Senden"-Button übersenden, erklären Sie sich damit
          einverstanden, dass wir Ihre Angaben für die Beantwortung Ihrer
          Anfrage bzw. Kontaktaufnahme verwenden. Ihre Daten werden nicht
          gespeichert. Eine Weitergabe an Dritte findet grundsätzlich nicht
          statt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für
          die Zukunft widerrufen. Im Falle des Widerrufs werden Ihre Daten
          umgehend gelöscht.
        </div>
        <div className="social-media">
          <span className="social-media-title">Folgen Sie uns!</span>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/lebenskreis_apotheke">
            <div className="social-media-icon">
              <span uk-icon="icon: instagram; ratio: 2"></span>
              <span>Instagram</span>
            </div>
          </a>
          <a target="_blank" rel="noreferrer" href="https://de-de.facebook.com/pages/category/Pharmacy---Drugstore/Lebenskreis-Apotheke-Zeltweg-247943495847837/">
            <div className="social-media-icon">
              <span uk-icon="icon: facebook; ratio: 2"></span>
              <span>Facebook</span>
            </div>
          </a>
        </div>
      </div>
      <Formular />
    </div>

    <h2 className="section-title">Anfahrt</h2>
    <div className="uk-grid section-bg">
      <div className="uk-width-1-2">
        <h3>Mit öffentlichen Verkehrsmitteln</h3>
        <p>
          Vom Bahnhof Zeltweg sind es nur 600 Meter zu Fuß. <br />
          Einfach mit dem Bahnhof im Rücken nach rechts starten, dem
          Straßenverlauf durch die Unterführung folgen.
          <br />
          Nach der Ampel befinden wir uns dann auf der linken Seite.
          <br />
        </p>
      </div>
      <div className="uk-width-1-2 google-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2711.2499605451594!2d14.7424135!3d47.1921199!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6c11145dda15b47!2sLebenskreis+Apotheke!5e0!3m2!1sde!2sat!4v1521115141161"
          title="Anfahrt"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
        />
      </div>
    </div>
  </div>
)
